import React, { useEffect, useRef } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkNetworkStatus } from "../actions/auth";
import { updateAppTitle } from "../helpers/utils";

const NetworkError = () => {
    const updatePolicyInterval = useRef();
    const dispatch = useDispatch();
    let navigate = useNavigate();

    useEffect(() => {
        updateAppTitle("Workspot Web Client - Network error document");
        let divElement = document.getElementById('app_main_id');
        const className = divElement.getAttribute("class")
        divElement.setAttribute("class", "wrapper_login");
        return () => {
            divElement.setAttribute("class", className);
        }
    }, [])

    useEffect(() => {
        updatePolicyInterval.current = setInterval(() => {
            dispatch(checkNetworkStatus())
                .then(() => {
                    clearInterval(updatePolicyInterval.current);
                    navigate('/');
                })
                .catch((error) => {
                    if (!error.status) {
                    }
                });
        }, 5 * 1000);

        return () => clearInterval(updatePolicyInterval.current);
    }, [dispatch]);
    return (
        <div className="max-w-300 max-w-auto">
            <div className="mb-3">
                <div className="alert alert-danger" role="alert">There was an error connecting. Please check your Internet connection. Your session will auto-reconnect when connection is resumed. </div>
            </div>
        </div>
    )
}

export default NetworkError;